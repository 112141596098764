import VueRouter from 'vue-router'
import Vue from 'vue'
import routess from './routes'
import {Message} from "element-ui";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
NProgress.configure({ showSpinner: false });
Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    console.log(location)
    originalPush.call(this, location);
}
let router = undefined;
if (process.env.NODE_ENV == 'development') {
     router = new VueRouter({
        routes: routess,
        mode: "hash",
    })
}else {
     router = new VueRouter({
        routes: routess,
        mode: "hash",
        base: '/dist/'
    })
}
/*const router = new VueRouter({
    routes: routess,
    mode: "hash",
    base: '/dist/'
})*/

//路由守卫
// to 目标路由对象
// from 当前导航正要离开的路由对象
// next 是一个函数，表示放行
router.beforeEach((to, from, next) => {
    NProgress.start();
    if (to.path == '/Login'){
        if (window.localStorage.getItem("token")){
            next({path:'/Index'})
        }else {
            next()
        }
    } else{
        if (window.localStorage.getItem('token')) {
            next()
        } else {
            Message.error("请先登录")
            next({path: '/Login'})
        }
    }
})
router.afterEach((to,from)=>{
    NProgress.done();
})
// // 解决ElementUI导航栏中重复点菜单报错问题
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//
//     return originalPush.call(this, location).catch(err => err)
// }
export default router
