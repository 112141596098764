import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from '@/vuex'
import router from "@/router";
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

/*Vue.directive("clickoutside", {
  bind: function (el) {
    let dom = el.querySelector(".el-input__inner");
    if (dom){
      dom.addEventListener("blur", function () {
        setTimeout(() => {
          dom.removeAttribute("readonly");
        }, 200);
      });
    }
  },
});*/
let fixElSelect = function () {
  document.querySelectorAll('.el-select[data-is-search="true"]:hover').forEach((v) => {
    let elInput = document.querySelector('.el-select[data-is-search="true"]:hover input[readonly]');
    if (elInput) {
      elInput.readOnly = false;
      elInput.blur();
      elInput.focus();
    }
  });
};
document.addEventListener('focusin', fixElSelect);
document.addEventListener('点击',fixElSelect);
document.addEventListener('touchstart', fixElSelect);
