<template>
  <div class="login">
    <el-form :rules="rules" ref="loginForm" :model="loginForm" class="loginContainer">
      <h3 class="loginTitle">
        用户登录
      </h3>
      <el-form-item prop="phone">
        <el-input type="text" v-model="loginForm.phone" placeholder="亲，请输入用户名">
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="loginForm.password" placeholder="亲，请输入密码">
        </el-input>
      </el-form-item>
<!--      <el-checkbox v-model="checked" class="loginRemember">一周之内记住我</el-checkbox>-->
      <el-button v-loading="loading" type="primary" @keydown.enter="submitLogin" style="width:100%" @click="submitLogin">登录</el-button>
    </el-form>
<!--    备案-->

    <div class="footer">
      <span><a style="color:#989898;text-decoration: none" href="https://beian.miit.gov.cn/" target="_blank">ICP备案/许可证号：冀ICP备2022022529号-1</a></span>
      <div>|</div>
      <span style="display: flex;align-items: center">
        <img style="width: 17px;margin-right: 4px" alt=""
             src="../../public/c6c6840b95d51b1b406880fb0c56883.png" />
        <a style="color:#989898;text-decoration: none" href=
          "https://beian.mps.gov.cn/#/query/webSearch?code=13040302001442"
        rel="noreferrer" target="_blank">冀公网安备13040302001442</a></span>
    </div>
  </div>
</template>

<script>
import {postAction} from "@/util/request";

export default {
  name: "Login",
  data() {
    return {
      loading:false,
      captchaUrl: "",
      loginForm: {
        phone: "",
        password: ""
      },
      checked: false,
      rules: {
        phone: [{required: true, message: "请输入用户名", trigger: "blur"}],
        password: [{required: true, message: "请输入密码", trigger: "blur"}],
      },
    }
  },
  created() {
    console.log(process.env.NODE_ENV)
  },
  methods: {
    submitLogin() {
      let data = this.loginForm
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          postAction("people/login", data)
              .then((res) => {
                if (res.success) {
                  this.loading = false
                  //登录成功 将token放入local中，进行页面跳转携带参数
                  window.localStorage.setItem("token", res.result.token);
                  this.$store.commit('updateObj', res.result.obj)
                  this.$router.replace("/Index")
                } else {
                  this.$message.warning(res.message);
                  return
                }
              }).catch(e => {
                console.log(e)
            this.$message.error("登录失败，请重试")
          }).finally(()=>{
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.login {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0) url("../assets/images/img.webp") repeat;
  background-size: 100% 100%;
}

.loginContainer {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 180px auto;
  width: 350px;
  padding: 15px 35px 15px 35px;
  background: aliceblue;
  border: 1px solid blueviolet;
  box-shadow: 0 0 25px #f885ff;
}

.loginTitle {
  margin: 0px auto 48px auto;
  text-align: center;
  font-size: 40px;
}

.loginRemember {
  text-align: left;
  margin: 0px 0px 15px 0px;
}
.footer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 60px;
  position:fixed;
  bottom: 0;
  background-color: #333333;
  color: #989898;
}
.footer span{
  display: block;
  text-align: center;
  margin: 0 7px;
}
</style>
