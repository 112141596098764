import axios from "../config/axios";
export function getAction(url, params) {
    return axios({
        url: url,
        method: 'get',
        params: params
    })
}
export function postAction(url, params) {
    return axios({
        url: url,
        method: 'post',
        data: params
    })
}
export function fileAction(url, params) {
    return axios({
        url: url,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
export function download(url,params){
    return axios({
        url: url,
        method: 'get',
        params: params,
        responseType:'blob',
    })
}
export function getUrl(url){
    return axios({
        url: url,
        method: 'get',
        responseType:'blob',
    })
}