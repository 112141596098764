<template>
  <div class="hello">
    <el-container>
      <el-header><span
        style="font-size: 35px;color:#6989a5;">安&emsp;妮&emsp;电&emsp;竞&emsp;报&emsp;单&emsp;系&emsp;统</span>
      </el-header>
      <div class="name" style="position: fixed;top: 15px;right: 160px">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            Hello! &nbsp;
            {{ $store.state.obj.name }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item><span @click="logout">退出登录</span></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-container v-loading="loading">
        <el-aside id="side" width="auto" v-if="role.role || role.role==0">
          <div class="menu-btn" @click="menuDeal" style="height: 35px">
          </div>
          <el-menu class="el-menu-vertical-demo" :collapse="isCollapse" v-if="role.role==1"
                   background-color="#545c64"
                   text-color="#fff"
                   active-text-color="#ffd04b">
            <el-menu-item index="1" @click="to('/OrderList')">
              <i class="el-icon-s-order"></i>
              <span slot="title">订单信息查看</span>
            </el-menu-item>
            <el-menu-item v-if="role.isCaptain==1" index="2"
                          @click="to('/TeamOrderList')">
              <i class="el-icon-menu"></i>
              <span slot="title">团队订单查看</span>
            </el-menu-item>
            <el-menu-item index="3" @click="to('/PersonInfo')">
              <i class="el-icon-info"></i>
              <span slot="title">个人信息查看</span>
            </el-menu-item>
            <el-menu-item index="4" @click="to('/Role')">
              <i class="el-icon-s-ticket"></i>
              <span slot="title">店规信息查看</span>
            </el-menu-item>
          </el-menu>


          <el-menu class="el-menu-vertical-demo" :collapse="isCollapse"
                   v-if="role.role==0||role.role==-1"
                   background-color="#545c64"
                   text-color="#fff"
                   active-text-color="#ffd04b">
            <el-menu-item index="1" @click="to('/OrderMag')">
              <i class="el-icon-s-order"></i>
              <span slot="title">订单信息管理</span>
            </el-menu-item>
            <el-menu-item index="2" @click="to('/PeopleMag')">
              <i class="el-icon-user-solid"></i>
              <span slot="title">人员信息管理</span>
            </el-menu-item>
            <el-menu-item index="3" @click="to('/ProjectMag')">
              <i class="el-icon-s-cooperation"></i>
              <span slot="title">项目信息管理</span>
            </el-menu-item>
            <el-menu-item index="4" @click="to('/Laoban')">
              <i class="el-icon-s-custom"></i>
              <span slot="title">老板信息管理</span>
            </el-menu-item>
            <el-menu-item v-if="role.role==-1" index="8" @click="to('/Wall')">
              <i class="el-icon-s-tools"></i>
              <span slot="title">常规信息管理</span>
            </el-menu-item>
            <el-menu-item v-if="role.role==-1" index="9" @click="to('/HeadCover')">
              <i class="el-icon-s-order"></i>
              <span slot="title">封面信息管理</span>
            </el-menu-item>
            <el-menu-item index="5" @click="to('/PersonInfo')">
              <i class="el-icon-info"></i>
              <span slot="title">个人信息查看</span>
            </el-menu-item>
            <el-menu-item index="6" @click="to('/TeamInfo')">
              <i class="el-icon-s-grid"></i>
              <span slot="title">团队信息查看</span>
            </el-menu-item>
            <el-menu-item index="7" @click="to('/Role')">
              <i class="el-icon-s-ticket"></i>
              <span slot="title">店规信息查看</span>
            </el-menu-item>

          </el-menu>
        </el-aside>
        <el-main>
          <div class="box">
            <!--              <transition name="slide-right">-->
            <router-view class="router-view" v-if="viewR"></router-view>
            <!--              </transition>-->

            <!--            <div v-else class="bx"></div>-->
            <el-empty v-if="!viewR" :image-size="300" description="">
              <span slot="description"></span>
            </el-empty>

          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      loading: false,
      viewR: false,
      people: {},
      isCollapse: false,
      ju: '',
      role: {},
      connectCount: 0,
    }
  },
  watch: {},
  created() {
    this.role = this.$store.state.obj
    this.loading = true
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.loading = false
      }, 500)
    })
    this.connectCount = 0;
    this.connectWebsocket()
  },
  destroyed() {
    if (this.$store.state.ws){
      this.$store.state.ws.close()
    }
    this.$store.commit('removeWs')
  },
  methods: {
    connectWebsocket() {
      let url = 'wss://anxiaoni.com/api/ws/notice/' + this.$store.state.obj.id
      let ws = new WebSocket(url);
      // 连接打开时触发的事件
      ws.onopen = (event) => {
        console.log("Connection open ...");
      };
      ws.onmessage = (event) => {
        console.log("Received from server: " + event.data);
        this.$notify({
          title: '提示',
          message: event.data,
          duration: 0,
          type: 'success',
        })
        // 在这里处理从服务器接收到的数据
      };
      ws.onclose = (event) => {
        if (event.wasClean) {
          console.log('Connection closed cleanly, code=' + event.code + ' reason=' + event.reason);
        } else {
          console.error('Connection died');
        }
      };
      ws.onerror = (error) => {
        if (this.connectCount >= 3){
          return
        }
        this.connectCount ++
        console.error(error);
        this.connectWebsocket()
      };
      this.$store.commit('updateWs', ws)
    },
    logout() {
      this.loading = true
      window.localStorage.setItem("token", '')
      this.$router.replace("/Login")

    },
    to(e) {
      this.viewR = true
      this.$router.push(e, () => {
      }, (e) => {
        console.log(e)
      })
    },
    menuDeal() {
      this.isCollapse = !this.isCollapse
      /*let side = document.getElementById('side')
      console.log(side)
      if (this.isCollapse==true){
        side.style.width='64px !important'
      }else {
        side.style.width='200px !important'
      }*/
    },
    handleOpen() {

    },
    handleClose() {

    },
  }

}
</script>

<style>
.box {
  overflow-y: auto;
  overflow-x: hidden;

}

.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #545c64;
  color: #333;
  text-align: center;
  height: calc(100vh - 60px);
}

/*.el-aside:hover{
  width: 200px !important;
}*/

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  height: calc(100vh - 60px);
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.el-dropdown-link:hover {

}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}

.el-menu-vertical-demo {
  border-right: 0 !important;
}

.el-icon-arrow-down {
  font-size: 12px;
}

/*.el-aside:hover{
  width: 64px !important;
}*/
.bx {
  width: calc(100vw - 200px - 20px);
  height: calc(100vh - 60px - 40px);
  background: rgba(0, 0, 0, 0) url("../assets/images/background-image2.jpg") no-repeat scroll 100% 100%;
  background-size: 100% 100%;

}

.menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: rgb(84, 92, 100);
  transition: background-color 0.3s ease 0s;
  text-align: center;
  font-size: 22px;
}

.menu-btn:hover {
  background-color: rgb(67, 74, 80);
}

.menu-btn::before {
  content: '\2261';
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.router-view {
  /*height: 100%;*/
  /*position: absolute;*/
  /*margin: 0 auto;*/
  -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 500ms;
  position: absolute;
  backface-visibility: hidden;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-enter-to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.van-badge--fixed {
  z-index: 1000;
}
</style>
