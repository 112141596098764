const routes = [
    {
        path: "/",
        redirect: "/Login",
    },
    {
      path: '/Login',
        component: ()=>import("../components/Login")
    },
    {
        path:'/Index',
        name:'index',
        component: ()=>import("../components/HelloWorld"),
        children: [
            {
                path: '/Role',
                name: '/role',
                component: () => import('../components/RoleView')
            },
            {
                path: '/OrderList',
                name: 'orderList',
                component: () => import('../components/pw/OrderList')
            },
            {
                path: '/PersonInfo',
                nameL: 'personInfo',
                component: () => import('../components/pw/PeopleInfo')
            },
            {
                path: "/OrderMag",
                name: 'orderMag',
                component: () => import('../components/master/OrdersManager')
            },
            {
                path: "/PeopleMag",
                name: 'peopleMag',
                component: () => import('../components/master/PeopleManager')
            },
            {
                path: "/ProjectMag",
                name: 'projectMag',
                component: () => import('../components/master/ProjectManager')
            },
            {
                path: "/Laoban",
                name: 'laoban',
                component: () => import('../components/master/Laoban')
            },
            {
                path: "/TeamInfo",
                name: 'TeamInfo',
                component: () => import('../components/master/Team.vue')
            },
            {
                path: "/TeamOrderList",
                name: 'TeamOrderList',
                component: () => import('../components/pw/TeamOrderList.vue')
            },
            {
                path: "/Wall",
                name: "Wall",
                component: () => import('../components/uni/Wall.vue')
            },
            {
                path: "/HeadCover",
                name: "HeadCover",
                component: () => import('../components/uni/HeadCover.vue')
            }
        ]
    }

]
export default routes
